import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import envConfig from "../../config";
import { getCookie, getHeaders } from "../../utils/api";
import { enqueueSnackbar } from "notistack";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { formatDate } from "../../utils/common";

const LinkSubscriberToATO = ({
  subscriberDetails,
  fetchSubscriberDetails,
  selectedSubscriber,
  loading,
}) => {
  const { isDarkMode } = useTheme();
  const [agentType, setAgentType] = useState("taxAgent");
  const [saveLoading, setSaveLoading] = useState(false);

  const theme = isDarkMode ? darkTheme : lightTheme;
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleAgentChange = (event) => {
    setAgentType(event.target.value);
  };

  const onCompleteSteps = async () => {
    setSaveLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/confirm-subscriber-ssid?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getHeaders(),
          },
          body: JSON.stringify({
            SsId: subscriberDetails.xxsStpSsid,
            SubscriberId: selectedSubscriber?.xxsID,
            AgentTypeBorT: agentType === "taxAgent" ? "T" : "B",
          }),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        await fetchSubscriberDetails();
      } else {
        enqueueSnackbar(
          data.ErrorMessage || "Error occurred while processing the request",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        error || "Network error or failed to process the request",
        {
          variant: "error",
        }
      );
    } finally {
      setSaveLoading(false);
    }
  };

  if (!!subscriberDetails.xxsStpAtoLinkByFullName) {
    return (
      <Box pt={3}>
        <BackdropLoading open={loading || saveLoading} />
        <Typography
          variant="h4"
          gutterBottom
          fontSize={"24px"}
          marginBottom={"15px"}
        >
          Linked by {subscriberDetails.xxsStpAtoLinkByFullName} on{" "}
          {formatDate(subscriberDetails.xxsStpAtoLinkDateTime)}
        </Typography>
        <Typography variant="p" fontSize={16} marginBottom="30px">
          SSID: {subscriberDetails.xxsStpSsid}
        </Typography>
      </Box>
    );
  }

  return (
    <Box pt={3}>
      <BackdropLoading open={loading || saveLoading} />
      <Typography
        variant="h4"
        gutterBottom
        color={"#495570"}
        fontSize={"24px"}
        marginBottom={"30px"}
      >
        Instruction
      </Typography>
      <Typography paragraph>
        Follow the below procedure to link Exacc to ATO. This will allow you and
        authorised members to send STP and other reports on behalf of your
        client Business Files. You must have sufficient ATO permissions.
      </Typography>

      <Box
        sx={{
          li: {
            my: 3,
          },
        }}
      >
        <ol>
          <li>
            <Typography
              sx={{
                a: {
                  color: theme.palette.primary.main,
                },
              }}
            >
              Go to{" "}
              <a
                href="https://am.ato.gov.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://am.ato.gov.au
              </a>
            </Typography>
          </li>
          <li>
            <Typography>Login and select the practice.</Typography>
          </li>
          <li>
            <Typography>
              On left-hand side, you should have ‘Access Manager Links’, and ‘My
              hosted software services’. Click on{" "}
              <Typography variant="b" fontWeight={700}>
                ‘My hosted software services’
              </Typography>
              .
            </Typography>
          </li>
          <li>
            <Typography>
              Click on{" "}
              <Typography variant="b" fontWeight={700}>
                ‘Notify the ATO of your hosted service’
              </Typography>
              .
            </Typography>
          </li>
          <li>
            <Typography>
              Enter our ABN:{" "}
              <Typography variant="b" fontWeight={700}>
                86643294937
              </Typography>
              <Tooltip title="Copy ABN">
                <IconButton
                  size="small"
                  onClick={() => copyToClipboard("86643294937")}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </li>
          <li>
            <Typography>
              Enter your SSID:{" "}
              <Typography variant="b" fontWeight={700}>
                {subscriberDetails.xxsStpSsid}
              </Typography>
              <Tooltip title="Copy SSID">
                <IconButton
                  size="small"
                  onClick={() => copyToClipboard(subscriberDetails.xxsStpSsid)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
          </li>
        </ol>
      </Box>
      <Box mt={3}>
        <RadioGroup
          value={agentType}
          onChange={handleAgentChange}
          row
          aria-label="agent type"
          name="agent-type"
        >
          <FormControlLabel
            value="taxAgent"
            control={<Radio />}
            label="I Am A Tax Agent"
          />
          <FormControlLabel
            value="basAgent"
            control={<Radio />}
            label="I Am BAS Agent"
          />
        </RadioGroup>
      </Box>
      <Box mt={3}>
        <Typography fontWeight={700} variant="subtitle1">
          Did you complete all the steps above?
        </Typography>

        <Box mt={2} display="flex" alignItems="center" gap={2}>
          <Button variant="contained" color="primary" onClick={onCompleteSteps}>
            Yes I Did
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              window.open(
                `https://exacc.au/help/link-exacc-to-ato?ssid=${
                  subscriberDetails.xxsStpSsid
                }${
                  subscriberDetails?.xxsTaxCode
                    ? `&abn=${subscriberDetails.xxsTaxCode}`
                    : ""
                }`,
                "_blank"
              )
            }
          >
            No, I need help
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LinkSubscriberToATO;
