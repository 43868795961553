const { host } = window.location;

// const loginUrl = host.replace(/^https:\/\/(www\.)?/, "");

const envConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  olivsRootPassword: process.env.REACT_APP_OLIVS_ROOT_PASSWORD,
  loginUrl: host,
  apiDev1: host.includes(".app")
    ? "webservice02002.olivs.cloud"
    : host.includes(".ssx.")
    ? "dev1v-api.ssx.au"
    : "dev1.olivs.com",
  apiDev2: host.includes(".app")
    ? "tmwebservice.olivs.cloud"
    : host.includes(".ssx.")
    ? "dev2v-api.ssx.au"
    : "dev2.olivs.com",
  apiDev1Exacc: origin.includes(".app")
    ? "webservice01003.olivs.cloud"
    : origin.includes(".ssx.")
    ? "dev1z-api.ssx.au"
    : "api-dev1v.exacc.au",
  apiLogin: host.includes(".app")
    ? "login-api.olivs.app"
    : host.includes(".ssx.")
    ? "login-api.ssx.au"
    : "login-api.olivs.com",
  correspondingService: "",
  mainServiceUrl: "",
};

if (host === "login.olivs.com") {
  envConfig.mainServiceUrl = "s8.olivs.com";
  envConfig.correspondingService = "s1.olivs.com";
} else if (/^my\.exacc\.au$/.test(host)) {
  envConfig.mainServiceUrl = `s1.exacc.au`;
  envConfig.correspondingService = `s1v.exacc.au`;
} else if (/^my\.ssx\.au$/.test(host)) {
  envConfig.mainServiceUrl = `s1.ssx.au`;
  envConfig.correspondingService = `s1v.ssx.au`;
} else if (/^my\.olivs\.app$/.test(host)) {
  envConfig.mainServiceUrl = `s1.olivs.app`;
  envConfig.correspondingService = `s1v.olivs.app`;
} else {
  envConfig.mainServiceUrl = process.env.REACT_APP_MAIN_SERVICE_URL;
  envConfig.correspondingService = process.env.REACT_APP_CORRESPONDING_SERVICE;
}

export default envConfig;
