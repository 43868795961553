import { useState, useEffect, useCallback, useRef } from "react";
import Cookies from "js-cookie";

const useApiCallDefault = (
  url,
  method = "GET",
  headers = {},
  body = null,
  navigate = () => {}
) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const attemptsRef = useRef(1);
  const maxAttempts = 3;

  const fetchData = useCallback(async () => {
    if (attemptsRef.current > maxAttempts) {
      return;
      navigate("/");
    }

    try {
      const config = {
        method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          ...headers,
        },
      };

      if (body !== null) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(url, config);

      if (!response.ok) {
        attemptsRef.current += 1;
        await fetchData(); // Retry if not successful

        const responseData = {
          errorMessage:
            typeof response === "string" ? response : response?.errorMessage,
          time: new Date().toLocaleString(),
          url: url,
          businessFileName: Cookies.get("databaseName"),
          method: method,
          isAjaxRequest: true,
        };

        navigate("/");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error);
      attemptsRef.current += 1;
      setTimeout(async () => {
        await fetchData();
      }, 1000);
    } finally {
      setLoading(false);
    }
  }, [url, method, headers, body, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading };
};

export default useApiCallDefault;
