import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { getHeaders } from "../../utils/api";
import envConfig from "../../config";
import { AustralianBusinessNumber } from "../CreateBusinessAccount/CreateBusinessAccount";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const SubscriberDetails = ({
  selectedSubscriber,
  fullSubscriberDetails,
  loading,
  fetchSubscriberDetails,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [saveLoading, setSaveLoading] = useState(false);
  const [formData, setFormData] = useState({
    xxsName: "",
    xxsTaxCode: "",
    xxsContactName: "",
    xxsContactEmail: "",
    xxsContactPhone: "",
    xxsAgentRAN: "",
    xxsAgentPhone: "",
    xxsAgentName: "",
    xxsAgentEmail: "",
    // New Address Fields
    xxsBilStreet1: "",
    xxsBilStreet2: "",
    xxsBilSuburb: "",
    xxsBilState: "",
    xxsBilPostCode: "",
  });

  const [initialData, setInitialData] = useState(formData); // To reset data on cancel
  const [errors, setErrors] = useState({}); // Store validation errors

  // UseEffect to update formData when fullSubscriberDetails prop changes
  useEffect(() => {
    if (fullSubscriberDetails) {
      const newFormData = {
        xxsName: fullSubscriberDetails.xxsName || "",
        xxsTaxCode: fullSubscriberDetails.xxsTaxCode || "",
        xxsContactName: fullSubscriberDetails.xxsContactName || "",
        xxsContactEmail: fullSubscriberDetails.xxsContactEmail || "",
        xxsContactPhone: fullSubscriberDetails.xxsContactPhone || "",
        xxsAgentRAN: fullSubscriberDetails.xxsAgentRAN || "",
        xxsAgentPhone: fullSubscriberDetails.xxsAgentPhone || "",
        xxsAgentName: fullSubscriberDetails.xxsAgentName || "",
        xxsAgentEmail: fullSubscriberDetails.xxsAgentEmail || "",
        // Populate new address fields
        xxsBilStreet1: fullSubscriberDetails.xxsBilStreet1 || "",
        xxsBilStreet2: fullSubscriberDetails.xxsBilStreet2 || "",
        xxsBilSuburb: fullSubscriberDetails.xxsBilSuburb || "",
        xxsBilState: fullSubscriberDetails.xxsBilState || "",
        xxsBilPostCode: fullSubscriberDetails.xxsBilPostCode || "",
      };

      setFormData(newFormData);
      setInitialData(newFormData); // Set initialData for cancel reset
    }
  }, [fullSubscriberDetails]);

  // Handle form field change
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // If the field is State, ensure it's a valid option or empty
    if (name === "xxsBilState") {
      const validStates = [
        "",
        "ACT",
        "NSW",
        "NT",
        "QLD",
        "SA",
        "TAS",
        "VIC",
        "WA",
      ];
      if (!validStates.includes(value)) {
        return; // Do not update if the state is invalid
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validation logic for ABN, email, and address fields
  const validateForm = () => {
    let tempErrors = {};

    // Email validation
    if (!/\S+@\S+\.\S+/.test(formData.xxsContactEmail)) {
      tempErrors.xxsContactEmail = "Invalid email address";
    }

    // ABN validation
    if (!AustralianBusinessNumber(formData.xxsTaxCode)) {
      tempErrors.xxsTaxCode = "Invalid ABN";
    }

    // Post Code validation (Assuming Australian Post Code: 4 digits)
    if (formData.xxsBilPostCode && !/^\d{4}$/.test(formData.xxsBilPostCode)) {
      tempErrors.xxsBilPostCode = "Invalid Post Code";
    }

    // State validation (if required)
    // Uncomment the following if State is a required field
    /*
    if (!formData.xxsBilState) {
      tempErrors.xxsBilState = "State is required";
    }
    */

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Return true if no errors
  };

  // Save button handler (replace this with your API call)
  const handleSave = async () => {
    if (validateForm()) {
      setSaveLoading(true);

      const payload = {
        xxsID: selectedSubscriber?.xxsID,
        xxsName: formData.xxsName || fullSubscriberDetails?.xxsName,
        xxsTaxCode: formData.xxsTaxCode || fullSubscriberDetails?.xxsTaxCode,
        xxsOwner_xxuID: fullSubscriberDetails?.xxsOwner_xxuID,
        xxuOwnerLoginEmail:
          formData.xxsContactEmail || fullSubscriberDetails?.xxsContactEmail,
        xxsBilEmail:
          formData.xxsContactEmail || fullSubscriberDetails?.xxsContactEmail,
        xxsBilStreet1:
          formData.xxsBilStreet1 || fullSubscriberDetails?.xxsBilStreet1,
        xxsBilStreet2:
          formData.xxsBilStreet2 || fullSubscriberDetails?.xxsBilStreet2,
        xxsBilSuburb:
          formData.xxsBilSuburb || fullSubscriberDetails?.xxsBilSuburb,
        xxsBilState: formData.xxsBilState || fullSubscriberDetails?.xxsBilState,
        xxsBilPostCode:
          formData.xxsBilPostCode || fullSubscriberDetails?.xxsBilPostCode,
        xxsBilCountry: fullSubscriberDetails?.xxsBilCountry,
        xxsCountry: fullSubscriberDetails?.xxsCountry,
        xxsContactName:
          formData.xxsContactName || fullSubscriberDetails?.xxsContactName,
        xxsContactEmail:
          formData.xxsContactEmail || fullSubscriberDetails?.xxsContactEmail,
        xxsContactPhone:
          formData.xxsContactPhone || fullSubscriberDetails?.xxsContactPhone,
        xxsCCType: fullSubscriberDetails?.xxsCCType,
        xxsCCNumber: fullSubscriberDetails?.xxsCCNumber,
        xxsCCExpMonth: fullSubscriberDetails?.xxsCCExpMonth,
        xxsCCExpYear: fullSubscriberDetails?.xxsCCExpYear,
        xxsCCCode: fullSubscriberDetails?.xxsCCCode,
        xxsBilContact_xxuID: fullSubscriberDetails?.xxsBilContact_xxuID, // Assuming this comes from selectedSubscriber
        xxsBilPeriod: fullSubscriberDetails?.xxsBilPeriod,
        xxsBilDiscount: fullSubscriberDetails?.xxsBilDiscount,
        xxsOwnFile_xxbID: fullSubscriberDetails?.xxsOwnFile_xxbID,
        xxsReInvoice: fullSubscriberDetails?.xxsReInvoice,
        xxsReInvDisc: fullSubscriberDetails?.xxsReInvDisc,
        xxsPrivNote: fullSubscriberDetails?.xxsPrivNote,
        xxsCreatedDateTime: fullSubscriberDetails?.xxsCreatedDateTime,
        xxsCreatedBy_xxuID: fullSubscriberDetails?.xxsCreatedBy_xxuID,
        xxrStatus: fullSubscriberDetails?.xxrStatus,
        xxsAgentRAN: formData.xxsAgentRAN || fullSubscriberDetails?.xxsAgentRAN,
        xxsAgentPhone:
          formData.xxsAgentPhone || fullSubscriberDetails?.xxsAgentPhone,
        xxsAgentName:
          formData.xxsAgentName || fullSubscriberDetails?.xxsAgentName,
        xxsAgentEmail:
          formData.xxsAgentEmail || fullSubscriberDetails?.xxsAgentEmail,
        xxsFinancialsMyInfo: fullSubscriberDetails?.xxsFinancialsMyInfo,
        xxsStpAtoLinkBy_xxuID: fullSubscriberDetails?.xxsStpAtoLinkBy_xxuID,
        xxsStpAtoLinkDateTime: fullSubscriberDetails?.xxsStpAtoLinkDateTime,
      };

      try {
        const response = await fetch(
          `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/save-subscriber-details?BaseHostURL=s1.olivs.com`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...getHeaders(),
            },
            body: JSON.stringify(payload),
          }
        );

        const result = await response.json();

        if (result.SuccessYN === "Y") {
          enqueueSnackbar("Subscriber details updated successfully", {
            variant: "success",
          });
          fetchSubscriberDetails(); // Refetch subscriber details to reflect the updated data
        } else {
          enqueueSnackbar(
            result.ErrorMessage || "Failed to update subscriber details",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        enqueueSnackbar("Error updating subscriber details", {
          variant: "error",
        });
      } finally {
        setSaveLoading(false);
      }
    } else {
      enqueueSnackbar("Please fix the errors before saving.", {
        variant: "error",
      });
    }
  };

  // Cancel button handler (reverts changes back to initial state)
  const handleCancel = () => {
    setFormData(initialData);
    setErrors({});
  };

  // Define state options
  const stateOptions = [
    "",
    "ACT",
    "NSW",
    "NT",
    "QLD",
    "SA",
    "TAS",
    "VIC",
    "WA",
  ];

  return (
    <Box pt={3}>
      <BackdropLoading open={loading || saveLoading} />
      <Typography mb={3} variant="h5" gutterBottom>
        Details
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={6}>
        {/* Business Name */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Business Name"
            name="xxsName"
            value={formData.xxsName}
            onChange={handleInputChange}
          />
        </Grid>
        {/* ABN */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="ABN"
            name="xxsTaxCode"
            value={formData.xxsTaxCode}
            onChange={handleInputChange}
            error={!!errors.xxsTaxCode}
            helperText={errors.xxsTaxCode}
          />
        </Grid>
        {/* Contact Full Name */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Contact Full Name"
            name="xxsContactName"
            value={formData.xxsContactName}
            onChange={handleInputChange}
          />
        </Grid>
        {/* Contact Email Address */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            label="Contact Email Address"
            name="xxsContactEmail"
            value={formData.xxsContactEmail}
            onChange={handleInputChange}
            error={!!errors.xxsContactEmail}
            helperText={errors.xxsContactEmail}
          />
        </Grid>
        {/* Contact Phone Number */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Contact Phone Number"
            name="xxsContactPhone"
            value={formData.xxsContactPhone}
            onChange={handleInputChange}
          />
        </Grid>

        {/* --- New Address Fields --- */}
        {/* Street 1 */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Street 1"
            name="xxsBilStreet1"
            value={formData.xxsBilStreet1}
            onChange={handleInputChange}
          />
        </Grid>
        {/* Street 2 */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Street 2"
            name="xxsBilStreet2"
            value={formData.xxsBilStreet2}
            onChange={handleInputChange}
          />
        </Grid>
        {/* Suburb */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Suburb"
            name="xxsBilSuburb"
            value={formData.xxsBilSuburb}
            onChange={handleInputChange}
          />
        </Grid>
        {/* State */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="state-label" sx={{ fontSize: 16 }}>
              State
            </InputLabel>
            <Select
              sx={{
                ".MuiSelect-select": {
                  minHeight: "23px !important",
                },
              }}
              labelId="state-label"
              label="State"
              name="xxsBilState"
              value={formData.xxsBilState}
              onChange={handleInputChange}
            >
              {stateOptions.map((state) => (
                <MenuItem key={state} value={state}>
                  {state || "Select State"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Post Code */}
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Post Code"
            name="xxsBilPostCode"
            value={formData.xxsBilPostCode}
            onChange={handleInputChange}
            error={!!errors.xxsBilPostCode}
            helperText={errors.xxsBilPostCode}
          />
        </Grid>
        {/* --- End of Address Fields --- */}
      </Grid>

      {/* --- Tax Agent Details --- */}
      <Typography variant="h5" gutterBottom sx={{ mt: 3, mb: 3 }}>
        Tax Agent Details
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={6}>
        {/* RAN */}
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="RAN"
            name="xxsAgentRAN"
            value={formData.xxsAgentRAN}
            onChange={handleInputChange}
          />
        </Grid>
        {/* Agent Name */}
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Name"
            name="xxsAgentName"
            value={formData.xxsAgentName}
            onChange={handleInputChange}
          />
        </Grid>
        {/* Agent Email */}
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Email"
            name="xxsAgentEmail"
            value={formData.xxsAgentEmail}
            onChange={handleInputChange}
          />
        </Grid>
        {/* Agent Phone */}
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              label: { fontSize: 16, marginTop: 0 },
            }}
            fullWidth
            label="Contact Phone Number"
            name="xxsAgentPhone"
            value={formData.xxsAgentPhone}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      {/* Save and Cancel buttons */}
      <Box mt={4} display="flex" gap={4}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriberDetails;
