import React, { useState } from "react";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { menuItems } from "../MainNavigation/main-navigation-utils";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { ReactComponent as SwitchBusinessIcon } from "../../assets/switch-account-icon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/log-out-icon.svg";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useNavigate, useParams } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { ReactComponent as FavouritesIcon } from "../../assets/star.svg";
import { ReactComponent as PayrollIcon } from "../../assets/payroll-menu-icon.svg";
import { ReactComponent as BusinessIcon } from "../../assets/helm.svg";
import { ReactComponent as FinanceIcon } from "../../assets/finance.svg";
import { ReactComponent as ReportsIcon } from "../../assets/reports.svg";
import { ReactComponent as HexagonIcon } from "../../assets/hexagon.svg";
import { getCookie, getLoginSecured } from "../../utils/api";
import { useSnackbar } from "notistack";
import envConfig from "../../config";

const MobileMenuSection = ({
  icon = {},
  handleMenuClick = () => {},
  open,
  setOpen = () => {},
  companyName = "",
  currentMenuItem = "",
  urlPrefix = "",
  //
  mainMenuTitle = "",
  sectionOne = [],
  sectionOneTitle = "",
  sectionOneExtra = [],
  //
  sectionTwo = [],
  sectionTwoTitle = "",
  sectionTwoExtra = [],
  //
  sectionThree = [],
  sectionThreeTitle = "",
  sectionThreeExtra = [],
  //
  isCompanyTile,
  sectionFour = [],
  sectionFourTitle = "",
  sectionFourExtra = [],
  createSessionForExternal,
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  if (
    !sectionOne?.length > 0 &&
    !sectionTwo?.length > 0 &&
    !sectionThree?.length > 0 &&
    !sectionFour?.length > 0 &&
    !sectionOneExtra?.length > 0 &&
    !sectionTwoExtra?.length > 0 &&
    !sectionThreeExtra?.length > 0 &&
    !sectionFourExtra?.length > 0
  )
    return;

  return (
    <div>
      <ListItem
        onClick={() => {
          handleMenuClick(mainMenuTitle);
        }}
        sx={{
          cursor: "pointer",
          backgroundColor: theme.navigation.menuBackgroundColor,
          color: theme.navigation.extraMenuItemColor,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "24px",
            span: {
              color:
                currentMenuItem === mainMenuTitle
                  ? theme.navigation.activeIconColor
                  : isDarkMode
                  ? "#FFF"
                  : theme.navigation.iconColor,
              path: {
                fill:
                  currentMenuItem === mainMenuTitle
                    ? theme.navigation.activeIconColor
                    : isDarkMode
                    ? "#FFF"
                    : theme.navigation.iconColor,
              },
            },
          }}
        >
          {icon && (
            <span
              style={{
                marginRight: "18px",
                minWidth: "30px",
                position: "relative",
              }}
            >
              {!isCompanyTile && icon}
              {isCompanyTile && (
                <>
                  <span
                    style={{
                      textTransform: "uppercase",
                      color: theme.palette.primary.main,
                      fontSize: "9px",
                      top: "-6px",
                      width: "max-content",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {mainMenuTitle}
                  </span>
                </>
              )}
            </span>
          )}

          <ListItemText
            sx={{
              span: {
                fontWeight: 500,
                color: isDarkMode ? "#FFF" : "rgb(129, 133, 149)",
              },
            }}
            primary={isCompanyTile ? "Manage" : mainMenuTitle}
          />
        </Box>
      </ListItem>
      {sectionOne?.length > 0 && currentMenuItem === mainMenuTitle && (
        <List sx={{ padding: 0, margin: 0 }}>
          {sectionOne?.length > 0 && sectionOneTitle && (
            <Typography
              sx={{
                fontWeight: 600,
                color: theme.navigation.subpageTitleColor,
                fontFeatureSettings: "clig off, liga off",
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                letterSpacing: theme.typography.letterSpacing,
                paddingLeft: 9,
              }}
            >
              {sectionOneTitle}
            </Typography>
          )}
          {sectionOne?.map(
            (subpage, subIndex) =>
              (companyName !== "My Profile" ||
                subpage.type === "my_profile") && (
                <div key={subIndex}>
                  {subpage.Label && subpage.type !== "quick_link" && (
                    <ListItem
                      onClick={() => {
                        if (subpage.Hyperlink) {
                          console.log(subpage.Hyperlink);
                          if (subpage.Hyperlink.startsWith("https")) {
                            createSessionForExternal(subpage.Hyperlink);
                            setOpen(false);
                          } else {
                            navigate(`${subpage.Hyperlink}`);
                            setOpen(false);
                          }
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: theme.navigation.menuBackgroundColor,
                        color: theme.navigation.iconColor,
                      }}
                    >
                      <ListItemText
                        disableRipple
                        sx={{
                          span: {
                            cursor: "pointer",
                            paddingLeft: 11,
                            backgroundColor:
                              theme.navigation.menuBackgroundColor,
                            color: theme.navigation.subpageTitleColor,
                          },
                        }}
                        primary={subpage.Label}
                      />
                    </ListItem>
                  )}
                </div>
              )
          )}
          {sectionOneExtra?.map((extra, extraIndex) => (
            <>
              {/* {extraIndex === 0 && (
                <Divider
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
              )} */}
              <List
                disableRipple
                key={extraIndex}
                sx={{
                  "&:hover": {
                    bgcolor: "unset",
                    cursor: "pointer",
                    color: theme.navigation.hoverMenuItemColor,
                  },
                  paddingLeft: 11,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: theme.typography.letterSpacing,
                  color: theme.navigation.extraMenuItemColor,
                  marginBottom:
                    sectionOneExtra?.length - 1 === extraIndex ? 3 : 1,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (extra.Hyperlink) {
                    if (extra.Hyperlink.startsWith("https")) {
                      createSessionForExternal(extra.Hyperlink);
                      setOpen(false);
                    } else {
                      navigate(`${extra.Hyperlink}`);
                      setOpen(false);
                    }
                  }
                }}
              >
                {extra?.Label}
              </List>
            </>
          ))}
        </List>
      )}

      {/* 
      
      */}

      {sectionTwo?.length > 0 && currentMenuItem === mainMenuTitle && (
        <List sx={{ padding: 0, margin: 0 }}>
          {sectionTwo?.length > 0 && sectionTwoTitle && (
            <Typography
              sx={{
                fontWeight: 600,
                color: theme.navigation.subpageTitleColor,
                fontFeatureSettings: "clig off, liga off",
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                letterSpacing: theme.typography.letterSpacing,
                paddingLeft: 9,
              }}
            >
              {sectionTwoTitle}
            </Typography>
          )}
          {sectionTwo?.map(
            (subpage, subIndex) =>
              (companyName !== "My Profile" ||
                subpage.type === "my_profile") && (
                <div key={subIndex}>
                  {subpage.Label && subpage.type !== "quick_link" && (
                    <ListItem
                      onClick={() => {
                        if (subpage.Hyperlink) {
                          if (subpage.Hyperlink.startsWith("https")) {
                            createSessionForExternal(subpage.Hyperlink);
                            setOpen(false);
                          } else {
                            navigate(`${subpage.Hyperlink}`);
                            setOpen(false);
                          }
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: theme.navigation.menuBackgroundColor,
                        color: theme.navigation.iconColor,
                      }}
                    >
                      <ListItemText
                        disableRipple
                        sx={{
                          span: {
                            cursor: "pointer",
                            paddingLeft: 11,
                            backgroundColor:
                              theme.navigation.menuBackgroundColor,
                            color: theme.navigation.subpageTitleColor,
                          },
                        }}
                        primary={subpage.Label}
                      />
                    </ListItem>
                  )}
                </div>
              )
          )}
          {sectionTwoExtra?.map((extra, extraIndex) => (
            <>
              {/* {extraIndex === 0 && (
                <Divider
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
              )} */}
              <List
                disableRipple
                key={extraIndex}
                sx={{
                  "&:hover": {
                    bgcolor: "unset",
                    cursor: "pointer",
                    color: theme.navigation.hoverMenuItemColor,
                  },
                  paddingLeft: 11,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: theme.typography.letterSpacing,
                  color: theme.navigation.extraMenuItemColor,
                  marginBottom:
                    sectionTwoExtra?.length - 1 === extraIndex ? 3 : 1,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (extra.Hyperlink) {
                    if (extra.Hyperlink.startsWith("https")) {
                      createSessionForExternal(extra.Hyperlink);
                      setOpen(false);
                    } else {
                      navigate(`${extra.Hyperlink}`);
                      setOpen(false);
                    }
                  }
                }}
              >
                {extra?.Label}
              </List>
            </>
          ))}
        </List>
      )}

      {/* 
      
      */}

      {sectionThree?.length > 0 && currentMenuItem === mainMenuTitle && (
        <List sx={{ padding: 0, margin: 0 }}>
          {sectionThree?.length > 0 && sectionThreeTitle && (
            <Typography
              sx={{
                fontWeight: 600,
                color: theme.navigation.subpageTitleColor,
                fontFeatureSettings: "clig off, liga off",
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                letterSpacing: theme.typography.letterSpacing,
                paddingLeft: 9,
              }}
            >
              {sectionThreeTitle}
            </Typography>
          )}
          {sectionThree?.map(
            (subpage, subIndex) =>
              (companyName !== "My Profile" ||
                subpage.type === "my_profile") && (
                <div key={subIndex}>
                  {subpage.Label && subpage.type !== "quick_link" && (
                    <ListItem
                      onClick={() => {
                        if (subpage.Hyperlink) {
                          if (subpage.Hyperlink.startsWith("https")) {
                            createSessionForExternal(subpage.Hyperlink);
                            setOpen(false);
                          } else {
                            navigate(`${subpage.Hyperlink}`);
                            setOpen(false);
                          }
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: theme.navigation.menuBackgroundColor,
                        color: theme.navigation.iconColor,
                      }}
                    >
                      <ListItemText
                        disableRipple
                        sx={{
                          span: {
                            cursor: "pointer",
                            paddingLeft: 11,
                            backgroundColor:
                              theme.navigation.menuBackgroundColor,
                            color: theme.navigation.subpageTitleColor,
                          },
                        }}
                        primary={subpage.Label}
                      />
                    </ListItem>
                  )}
                </div>
              )
          )}
          {sectionThreeExtra?.map((extra, extraIndex) => (
            <>
              {/* {extraIndex === 0 && (
                <Divider
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
              )} */}
              <List
                disableRipple
                key={extraIndex}
                sx={{
                  "&:hover": {
                    bgcolor: "unset",
                    cursor: "pointer",
                    color: theme.navigation.hoverMenuItemColor,
                  },
                  paddingLeft: 11,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: theme.typography.letterSpacing,
                  color: theme.navigation.extraMenuItemColor,
                  marginBottom:
                    sectionThreeExtra?.length - 1 === extraIndex ? 3 : 1,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (extra.Hyperlink) {
                    if (extra.Hyperlink.startsWith("https")) {
                      createSessionForExternal(extra.Hyperlink);
                      setOpen(false);
                    } else {
                      navigate(`${extra.Hyperlink}`);
                      setOpen(false);
                    }
                  }
                }}
              >
                {extra?.Label}
              </List>
            </>
          ))}
        </List>
      )}

      {/* 
      
      */}

      {sectionFour?.length > 0 && currentMenuItem === mainMenuTitle && (
        <List sx={{ padding: 0, margin: 0 }}>
          {sectionFour?.length > 0 && sectionFourTitle && (
            <Typography
              sx={{
                fontWeight: 600,
                color: theme.navigation.subpageTitleColor,
                fontFeatureSettings: "clig off, liga off",
                fontFamily: theme.typography.fontFamily,
                fontSize: 16,
                letterSpacing: theme.typography.letterSpacing,
                paddingLeft: 9,
              }}
            >
              {sectionFourTitle}
            </Typography>
          )}
          {sectionFour?.map(
            (subpage, subIndex) =>
              (companyName !== "My Profile" ||
                subpage.type === "my_profile") && (
                <div key={subIndex}>
                  {subpage.Label && subpage.type !== "quick_link" && (
                    <ListItem
                      onClick={() => {
                        if (subpage.Hyperlink) {
                          if (subpage.Hyperlink.startsWith("https")) {
                            createSessionForExternal(subpage.Hyperlink);
                            setOpen(false);
                          } else {
                            navigate(`${subpage.Hyperlink}`);
                            setOpen(false);
                          }
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: theme.navigation.menuBackgroundColor,
                        color: theme.navigation.iconColor,
                      }}
                    >
                      <ListItemText
                        disableRipple
                        sx={{
                          span: {
                            cursor: "pointer",
                            paddingLeft: 11,
                            backgroundColor:
                              theme.navigation.menuBackgroundColor,
                            color: theme.navigation.subpageTitleColor,
                          },
                        }}
                        primary={subpage.Label}
                      />
                    </ListItem>
                  )}
                </div>
              )
          )}
          {sectionFourExtra?.map((extra, extraIndex) => (
            <>
              {/* {extraIndex === 0 && (
                <Divider
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
              )} */}
              <List
                disableRipple
                key={extraIndex}
                sx={{
                  "&:hover": {
                    bgcolor: "unset",
                    cursor: "pointer",
                    color: theme.navigation.hoverMenuItemColor,
                  },
                  paddingLeft: 11,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: theme.typography.letterSpacing,
                  color: theme.navigation.extraMenuItemColor,
                  marginBottom:
                    sectionFourExtra?.length - 1 === extraIndex ? 3 : 1,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (extra.Hyperlink) {
                    if (extra.Hyperlink.startsWith("https")) {
                      createSessionForExternal(extra.Hyperlink);
                      setOpen(false);
                    } else {
                      navigate(`${extra.Hyperlink}`);
                      setOpen(false);
                    }
                  }
                }}
              >
                {extra?.Label}
              </List>
            </>
          ))}
        </List>
      )}
      {open && currentMenuItem === mainMenuTitle && !isCompanyTile && (
        <Divider
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />
      )}
    </div>
  );
};

const MobileNavigation = ({ newMenuItems, urlPrefix }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState("");
  const [showLogoutTooltip, setShowLogoutTooltip] = useState(false);
  const [showSwitchTooltip, setShowSwitchTooltip] = useState(false);

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleMenuToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (label) => {
    if (label === currentMenuItem) {
      setCurrentMenuItem(null);
      return;
    }
    setCurrentMenuItem(label);
  };

  let handleSwitchBusinessTimeout;
  const handleSwitchBusiness = async () => {
    clearTimeout(handleSwitchBusinessTimeout);

    if (showSwitchTooltip) {
      navigate("/user-dashboard");
    } else {
      setShowLogoutTooltip(false);
      setShowSwitchTooltip(true);

      handleSwitchBusinessTimeout = setTimeout(() => {
        setShowSwitchTooltip(false);
      }, 5000);
    }
  };

  let handleLogOutTimeout;

  const handleLogOut = () => {
    clearTimeout(handleLogOutTimeout);

    if (showLogoutTooltip) {
      window.location = `https://${
        envConfig.loginUrl
      }/logout?apiKey=${getCookie("apiKey")}-${getCookie(
        "databaseId"
      )}&sessionId=${getCookie("sessionId")}`;
    } else {
      setShowSwitchTooltip(false);
      setShowLogoutTooltip(true);

      handleLogOutTimeout = setTimeout(() => {
        setShowLogoutTooltip(false);
      }, 5000);
    }
  };

  const createSessionForExternal = async (url) => {
    const newSessionApiUrl = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/create-new-session-instance?BaseHostURL=${envConfig.loginUrl}`;

    const sessionHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "olivs-api-real-ip": "127.0.0.2",
      "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
      "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
      "browser-agent": window.navigator.userAgent,
      "session-id": getCookie("sessionId"),
      "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
    };

    const initialTokenData = JSON.stringify({
      LoginSecuredHash: getLoginSecured(),
      DatabaseId: getCookie("databaseId") || 0,
      CurrentHost: `https://${envConfig.loginUrl}`,
      LoginPageUrl: `https://${envConfig.loginUrl}`,
      RedirectPageUrl: url,
      IpAddress: "127.0.0.2",
      UserAgentAkaBrowserDetails: navigator.userAgent,
      ReferrerUrl: window.location.origin,
    });
    const sessionManagementResponse = await fetch(newSessionApiUrl, {
      method: "POST",
      headers: sessionHeaders,
      body: initialTokenData,
    });

    const sessionManagementJson = await sessionManagementResponse.json();
    if (sessionManagementJson?.successYN === "Y") {
      window.location = sessionManagementJson?.redirectPageUrl;
    } else {
      console.log("nav went wrong");
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleMenuToggle}>
        <MenuOutlinedIcon />
      </IconButton>
      <Drawer
        sx={{ zIndex: 9999999999 }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minWidth: "320px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <MobileMenuSection
              createSessionForExternal={createSessionForExternal}
              icon={
                <FavouritesIcon
                  width={24}
                  height={24}
                  style={{ marginLeft: "2px" }}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Favorites"
              sectionOneTitle="Favorites"
              sectionOne={newMenuItems?.Me_QuickLinks}
            />
            <MobileMenuSection
              createSessionForExternal={createSessionForExternal}
              icon={
                <PayrollIcon
                  width={24}
                  height={24}
                  style={{ marginLeft: "2px" }}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Payroll"
              sectionOne={newMenuItems?.Payroll_Payroll_Links}
              sectionOneTitle="Payroll"
              sectionOneExtra={newMenuItems?.Payroll_Payroll_Extras}
              sectionTwo={newMenuItems?.Payroll_Rosters_Links}
              sectionTwoTitle="Rosters"
              sectionTwoExtra={newMenuItems?.Payroll_Rosters_Extras}
              sectionThree={newMenuItems?.Payroll_Timesheet_Links}
              sectionThreeTitle="Timesheet"
              sectionThreeExtra={newMenuItems?.Payroll_Timesheet_Extras}
            />
            <MobileMenuSection
              createSessionForExternal={createSessionForExternal}
              icon={
                <BusinessIcon
                  width={23}
                  height={24}
                  style={{ marginLeft: "3px" }}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Business"
              sectionOne={newMenuItems?.Contacts_Links}
              sectionOneTitle="Contacts"
              sectionOneExtra={newMenuItems?.Contacts_Extras}
              sectionTwo={newMenuItems?.Invoices_Purchases_Links}
              sectionTwoTitle="Purchases"
              sectionTwoExtra={newMenuItems?.Invoices_Purchases_Extras}
              sectionThree={newMenuItems?.Invoices_Sales_Links}
              sectionThreeTitle="Sales"
              sectionThreeExtra={newMenuItems?.Invoices_Sales_Extras}
            />

            <MobileMenuSection
              createSessionForExternal={createSessionForExternal}
              icon={
                <FinanceIcon
                  style={{ marginBottom: "-3px" }}
                  width={30}
                  height={30}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Finance"
              sectionOneTitle="Accounting"
              sectionOne={newMenuItems?.Accounting_Links}
              sectionOneExtra={newMenuItems?.Accounting_Extras}
              sectionTwoTitle="Banking"
              sectionTwo={newMenuItems?.Accounting_Banking_Links}
              sectionTwoExtra={newMenuItems?.Accounting_Banking_Extras}
            />
            <MobileMenuSection
              createSessionForExternal={createSessionForExternal}
              icon={<ReportsIcon width={28} height={28} />}
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Reports"
              sectionOne={newMenuItems?.Reports}
            />

            <MobileMenuSection
              createSessionForExternal={createSessionForExternal}
              icon={
                <HexagonIcon
                  style={{ marginLeft: "2px", marginBottom: "-1px" }}
                  width={27}
                  height={27}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              mainMenuTitle={newMenuItems?.BusinessCode}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              isCompanyTile={true}
              sectionOne={
                newMenuItems?.Me_Configuration?.length
                  ? newMenuItems?.Me_Configuration
                  : newMenuItems.Subscribers
              }
              sectionOneTitle={
                newMenuItems?.Me_Configuration?.length
                  ? "Configuration"
                  : "Subscribers"
              }
              sectionOneExtra={newMenuItems?.Subscribers_Extra}
              sectionTwo={newMenuItems?.Me_MyProfile}
              sectionTwoTitle="My Profile"
              sectionFour={
                newMenuItems?.Me_Users?.length
                  ? newMenuItems?.Me_Users
                  : newMenuItems?.GlobalProfile
              }
              sectionFourTitle={
                newMenuItems?.Me_Users?.length ? "Users" : "My Profile"
              }
              // sectionFourExtra={[
              //   {
              //     type: "dark_mode",
              //   },
              //   {
              //     name: "Switch Business",
              //     type: "switch_business",
              //     Hyperlink: "/olivs/change-business-file",
              //     Label: "Switch business",
              //   },
              //   {
              //     name: "Log Out",
              //     type: "log_out",
              //     Hyperlink: "https://www.login.olivs.com/log-out",
              //     Label: "Log out",
              //   },
              // ]}
            />
          </Box>
          <Divider />
          {showSwitchTooltip && (
            <Box
              sx={{
                position: "absolute",
                bottom: "70px",
                width: "80%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#313131",
                fontSize: "0.875rem",
                letterSpacing: "0.01071em",
                color: "#fff",
                alignItems: "center",
                padding: "6px 16px",
                borderRadius: "8px",
                boxShadow:
                  "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
              }}
            >
              Click again to switch business
            </Box>
          )}
          {showLogoutTooltip && (
            <Box
              sx={{
                position: "absolute",
                bottom: "70px",
                width: "80%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#313131",
                fontSize: "0.875rem",
                letterSpacing: "0.01071em",
                color: "#fff",
                alignItems: "center",
                padding: "6px 16px",
                borderRadius: "8px",
                boxShadow:
                  "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
              }}
            >
              Click again to log out
            </Box>
          )}
          <Box
            sx={{ display: "flex", ".MuiTypography-root": { display: "none" } }}
          >
            <ListItem
              onClick={() => handleMenuClick("Dark Mode")}
              sx={{
                cursor: "pointer",
                backgroundColor: theme.navigation.menuBackgroundColor,
                color: theme.navigation.extraMenuItemColor,
                label: { margin: 0 },
                justifyContent: "center",
              }}
            >
              <ThemeToggle titlefontSize={16} />
            </ListItem>
            <ListItem
              onClick={() => handleSwitchBusiness()}
              sx={{
                cursor: "pointer",
                width: "calc(100% - 12px)",
                backgroundColor: theme.navigation.menuBackgroundColor,
                color: theme.navigation.extraMenuItemColor,
                justifyContent: "center",
              }}
            >
              <SwitchBusinessIcon width={32} height={32} />
              {/* <ListItemText
                // sx={{ marginLeft: "23px" }}
                primary="Switch Business"
              /> */}
            </ListItem>
            <ListItem
              onClick={() => handleLogOut()}
              sx={{
                cursor: "pointer",
                // marginLeft: "12px",
                width: "calc(100% - 12px)",
                backgroundColor: theme.navigation.menuBackgroundColor,
                color: theme.navigation.extraMenuItemColor,
                justifyContent: "center",
              }}
            >
              <LogOutIcon width={32} height={32} />
              {/* <ListItemText sx={{ marginLeft: "23px" }} primary="Log Out" /> */}
            </ListItem>
          </Box>
        </List>
      </Drawer>
    </>
  );
};

export default MobileNavigation;
