import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { getCookie } from "../../utils/api";
import envConfig from "../../config";
import { checkAndUpdateApiKeyExpiration } from "../Dashboard/Dashboard";

const transformString = (inputString) => {
  const words = inputString.split(/\s+/);

  const transformedWords = words.map((word) => {
    const lowercasedWord = word.toLowerCase();
    return lowercasedWord.charAt(0).toUpperCase() + lowercasedWord.slice(1);
  });
  return transformedWords.join(" ");
};

const LookupAbn = ({
  businessName,
  abn,
  abnApiResult,
  setAbnApiResult,
  setIsAbnOpen,
  setAbnResultsAccepted,
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `https://${envConfig.apiDev1}/api/v3/en-au/contacts/abn-lookup?taxCode=${abn}&BaseHostURL=${envConfig.loginUrl}`,
          null,
          {
            headers: {
              Accept: "application/json",
              "olivs-api-real-ip": "31.42.0.186",
              "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
              "session-id": getCookie("sessionId"),
              "browser-agent":
                "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "api-key": "OlivsAuth",
            },
          }
        );
        checkAndUpdateApiKeyExpiration();
        setAbnApiResult(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsAbnOpen(false);
        enqueueSnackbar({ message: error, variant: "error" });
      }
    };
    fetchData();
  }, []);

  if (abnApiResult?.ErrorMessage || abnApiResult?.ErrorMessage !== "") return;
  if (isLoading) return;
  const displayName = abnApiResult.LastName
    ? abnApiResult.OtherName
      ? `${abnApiResult.FirstName} ${abnApiResult.OtherName} ${abnApiResult.LastName}`
      : `${abnApiResult.FirstName} ${abnApiResult.LastName}`
    : abnApiResult.EntityName;
  return (
    <div>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mb: 1,
          }}
        >
          <Typography sx={{ width: "210px" }}>Business Name:</Typography>
          <Typography>{displayName}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",

            mb: 1,
          }}
        >
          <Typography sx={{ width: "210px" }}>Business Type:</Typography>
          <Typography>{abnApiResult.EntityDescription}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mb: 1,
          }}
        >
          <Typography sx={{ width: "210px" }}>Is GST Registered:</Typography>
          <Typography>{abnApiResult.GSTRegistered}</Typography>
        </Box>
        {abn && (
          <p>
            {`Check the record on `}
            <a
              style={{
                marginTop: "30px",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href={`https://abn.business.gov.au/ABN/View?abn=${abn}`}
              target="_blank"
            >
              ABN Lookup
            </a>
          </p>
        )}
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          textAlign: "right",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 1, ml: 2, mr: 2 }}
          onClick={() => {
            console.log("abnApiResult", abnApiResult);
            setAbnResultsAccepted({ ...abnApiResult, EntityName: displayName });
            setIsAbnOpen(false);
          }}
        >
          {`Apply as ${displayName}`}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 2, mr: 2 }}
          onClick={() => {
            console.log("abnApiResult", abnApiResult);
            setAbnResultsAccepted({
              ...abnApiResult,
              EntityName: transformString(displayName),
            });
            setIsAbnOpen(false);
          }}
        >
          {`Apply as ${transformString(displayName)}`}
        </Button>
        <Button
          sx={{
            background: "transparent",
            height: "36px",
            mr: "9px",
            mb: 1,
            "&:hover": {
              background: "transparent",
            },
          }}
          disableRipple
          variant="secondary"
          onClick={() => setIsAbnOpen(false)}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
};

export default LookupAbn;
