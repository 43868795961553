import Cookies from "js-cookie";
import md5 from "md5";
import envConfig from "../config";

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const getLoginSecured = (login, password) => {
  if (!login && !password && getCookie("loginSecuredHash")) {
    return getCookie("loginSecuredHash");
  }
  const loginEmail = login || getCookie("loginEmail");
  const loginPassword = password;
  const passwordFromCookies = getCookie("loginPassword");
  let hashedPassword;
  if (loginPassword) {
    hashedPassword = md5(loginPassword);
  } else {
    hashedPassword = passwordFromCookies;
  }
  if (!hashedPassword) {
    hashedPassword = passwordFromCookies;
  }
  console.log("hashedPassword", hashedPassword);
  const combinedString = `${loginEmail}:${hashedPassword}`;
  if (combinedString === "undefined:undefined") {
    console.log("abort");
    return;
  }
  const base64Encoded = btoa(combinedString);
  Cookies.set("loginSecuredHash", base64Encoded, { path: "/" });

  return base64Encoded;
};

export const getHeaders = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": "31.42.0.186",
    "olivs-api-computer-name": "BTM-WS2019",
    "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
    "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
  };

  if (getCookie("sessionId")) {
    return {
      ...headers,
      "browser-agent": window.navigator.userAgent,
      "session-id": getCookie("sessionId"),
    };
  }
  return headers;
};

export const default_headers = {
  Accept: "application/json",
  "olivs-api-real-ip": "31.42.0.186",
  "olivs-api-computer-name": "BTM-WS2019",
  "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
  "api-key": "OlivsAuth",
};

export const LOGIN_API_OLIVS_APP_URL = `https://${envConfig.apiLogin}/api/v1/`;
