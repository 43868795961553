import React, { useState } from "react";
import {
  Container,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../../config";
import { getCookie } from "../../utils/api";
import ReCAPTCHA from "react-google-recaptcha";

const InviteSubscriberPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isDarkMode } = useTheme();
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    loginEmail: "",
    allowAddNewBusinessFiles: false,
    allowManageOtherUsers: false,
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.checked,
    }));
  };

  const handleInputChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.loginEmail.trim()) {
      newErrors.loginEmail = "Login Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.loginEmail)) {
      newErrors.loginEmail = "Invalid email address";
    }
    if (!formData.termsAccepted) {
      newErrors.termsAccepted = "This field is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInvite = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const payload = {
      FirstName: formData.firstName,
      LastName: formData.lastName,
      LoginEmail: formData.loginEmail,
      AllowAddNewBusinessFilesYN: formData.allowAddNewBusinessFiles ? "Y" : "N",
      AllowManageOtherUsersYN: formData.allowManageOtherUsers ? "Y" : "N",
      SubscriberId: parseInt(id),
    };

    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v4/en-au/subscribers/invite-new-subscriber-member`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": `${getCookie("apiKey")}-0`,
            captcha: envConfig.loginUrl + ":" + recaptchaValue,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        enqueueSnackbar("User invited successfully!", { variant: "success" });
        // Reset form
        setFormData({
          firstName: "",
          lastName: "",
          loginEmail: "",
          allowAddNewBusinessFiles: false,
          allowManageOtherUsers: false,
          termsAccepted: false,
        });
      } else {
        enqueueSnackbar(data.ErrorMessage || "An error occurred", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error inviting subscriber member:", error);
      enqueueSnackbar("Failed to invite subscriber member", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/subscriber/${id}`);
  };

  return (
    <Container maxWidth="sm" sx={{ mb: 3 }}>
      <Typography variant="h4" gutterBottom>
        New Subscriber Member
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          required
          fullWidth
          label="First Name"
          value={formData.firstName}
          onChange={handleInputChange("firstName")}
          margin="normal"
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
        <TextField
          required
          fullWidth
          label="Last Name"
          value={formData.lastName}
          onChange={handleInputChange("lastName")}
          margin="normal"
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        <TextField
          required
          fullWidth
          label="Login Email"
          value={formData.loginEmail}
          onChange={handleInputChange("loginEmail")}
          margin="normal"
          error={!!errors.loginEmail}
          helperText={errors.loginEmail}
        />

        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.allowAddNewBusinessFiles}
                onChange={handleCheckboxChange("allowAddNewBusinessFiles")}
              />
            }
            label="Allow to add new Business Files"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.allowManageOtherUsers}
                onChange={handleCheckboxChange("allowManageOtherUsers")}
              />
            }
            label="Allow to manage other members"
          />
        </Box>

        {/* Terms and Conditions */}
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleCheckboxChange("termsAccepted")}
              />
            }
            label={
              <Typography>
                I am authorised to provide these details according to{" "}
                <a
                  className="link"
                  href="https://exacc.au/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: isDarkMode ? "#AAD269" : "#849F23",
                    cursor: "pointer",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                  }}
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://exacc.au/privacy-policy"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: isDarkMode ? "#AAD269" : "#849F23",
                    cursor: "pointer",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
                <span style={{ color: "#D32F2F" }}>*</span>
              </Typography>
            }
          />
          {errors.termsAccepted && (
            <Typography color="error" variant="caption">
              {errors.termsAccepted}
            </Typography>
          )}
        </Box>

        <Box sx={{ mt: 2 }}>
          <ReCAPTCHA
            sitekey={
              window.location.origin.includes("login")
                ? process.env.REACT_APP_SITE_KEY_LOGIN_OLIVS
                : window.location.origin.includes("olivs.app")
                ? process.env.REACT_APP_SITE_KEY_OLIVS_APP
                : window.location.origin.includes(".ssx.")
                ? process.env.REACT_APP_SITE_KEY_SSX
                : process.env.REACT_APP_SITE_KEY_MY_EXACC
            }
            onChange={handleRecaptcha}
            theme={isDarkMode ? "dark" : "light"}
            onExpired={() => setRecaptchaValue(null)}
          />
        </Box>

        <Grid container spacing={2} mt={2} sx={{ pb: 3 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInvite}
              disabled={loading || !recaptchaValue}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Invite
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default InviteSubscriberPage;
