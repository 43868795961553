import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { useSnackbar } from "notistack";
import { LOGIN_API_OLIVS_APP_URL } from "../../utils/api";
import envConfig from "../../config";
import { deleteAllCookies } from "../../utils/common";

const SessionManagement = () => {
  const { lang, dbId, sessionGuid } = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url =
          LOGIN_API_OLIVS_APP_URL +
          `en-au/session-management/register-new-session-instance?BaseHostURL=${envConfig.loginUrl}`;

        const responseApiCall = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "olivs-api-real-ip": "127.0.0.2",
            "olivs-api-computer-name": "BTM-WS2019",
            "olivs-root-password": envConfig.olivsRootPassword,
            "api-key": "OlivsAuth",
            "Content-Type": "application/json",
            "browser-agent": window.navigator.userAgent,
          },
          body: JSON.stringify({
            IpAddress: "127.0.0.2",
            UserAgentAkaBrowserDetails: navigator.userAgent,
            CurrentHost: window.location.origin,
            SessionGuid: sessionGuid,
          }),
        });

        const dataApiCall = await responseApiCall.json();
        if (
          !responseApiCall.ok ||
          !dataApiCall ||
          dataApiCall.successYN !== "Y"
        ) {
          if (
            dataApiCall?.errorMessage === "Session Guid has been expired" ||
            dataApiCall?.errorMessage === "Session Guid has been used before"
          ) {
            const savedReferrer = localStorage.getItem("savedReferrer");
            if (savedReferrer) {
              console.log("savedReferrer", savedReferrer);
              localStorage.removeItem("savedReferrer");
              window.location.href = savedReferrer;
              return;
            }
            setErrorMessage(dataApiCall?.errorMessage);
          }
        } else {
          console.log("set referrer to: ", dataApiCall.referrerUrl);
          localStorage.setItem("savedReferrer", dataApiCall.referrerUrl);
          document.cookie = `loginEmail=${dataApiCall.loginEmail}; path=/`;
          document.cookie = `loginPassword=${dataApiCall.loginPassword}; path=/`;
          document.cookie = `sessionGuid=${sessionGuid}; path=/`;
          document.cookie = `globalUserId=${dataApiCall.globalUserID}; path=/`;
          document.cookie = `databaseId=${dataApiCall.databaseID}; path=/`;
          document.cookie = `login2FASecKey=${dataApiCall.login2FASecKey}; path=/`;
          document.cookie = `login2FaCodeLastUsedUTCDate=${dataApiCall.login2FaCodeLastUsedUTCDate}; path=/`;
          document.cookie = `loginLastSec2CheckInUTCDate=${dataApiCall.LoginLastSec2CheckInUTCDate}; path=/`;
          document.cookie = `loginLastPwdUTCDate=${dataApiCall.loginLastPwdUTCDate}; path=/`;
          document.cookie = `apiKeyExpirationUTCDate=${dataApiCall.apiKeyExpirationUTCDate}; path=/`;

          document.cookie = `sessionId=${dataApiCall.sessionId}; path=/`;

          document.cookie = `apiKey=${dataApiCall.apiKey}; path=/`;

          const path =
            new URL(dataApiCall?.redirectUrl)?.pathname +
            new URL(dataApiCall?.redirectUrl)?.search;

          if (path) {
            console.log("path: ", path);
            navigate(`${path}`);
          } else {
            navigate(`/user-dashboard`);
          }
        }
      } catch (error) {
        console.error("Error fetching IP address or making API call:", error);
        setErrorMessage(error);
      }
    };

    fetchData();
  }, [navigate, sessionGuid]);

  return (
    <div>{errorMessage && <Alert severity="error">{errorMessage}</Alert>}</div>
  );
};

export default SessionManagement;
