import { useState, useEffect, useCallback } from "react";
import { getCookie } from "../utils/api";

const useApiCall = ({
  url,
  method = "GET",
  headers = {},
  body = null,
  navigate = () => {},
  skip = false,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const config = {
        method,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          ...headers,
          "api-key": getCookie("apiKey") + "-" + getCookie("databaseId"),
        },
      };

      if (body !== null) {
        config.body = JSON.stringify(body);
      }
      const response = await fetch(url, config);

      const result = await response.json();
      if (!response.ok) {
        if (result === "Wrong API format!" || result.startsWith("API Key")) {
          navigate("/");
        }

        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url, method, headers, body]);

  useEffect(() => {
    if (skip) return;
    fetchData();
  }, []);

  return { data, error, loading };
};

export default useApiCall;
