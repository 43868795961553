import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { getCookie, getLoginSecured } from "../../utils/api";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import envConfig from "../../config";
import { deleteAllCookies } from "../../utils/common";

const LoginPopup = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [remember, setRemember] = useState(false);
  const [loginEmail, setLoginEmail] = useState();
  const [smsSent, setSmsSent] = useState();

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleLogin = async () => {
    const storedTime = getCookie("apiKeyExpirationUTCDate");

    if (!storedTime) {
      console.log("s", storedTime);
      window.location = `https://${envConfig.loginUrl}/`;
      return;
    }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(storedTime + "Z").toUTCString();
    const difference =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    const maxDiff = envConfig.loginUrl.includes(".app") ? 1 : 5;
    if (difference < maxDiff * 60 * 1000) {
      await generateNewApiKeyAndValidate2FA();
    } else {
      const url = `https://${envConfig.apiLogin}/api/v1/en-au/system/extend-api-key-by-reentering-password-with-2fa?BaseHostURL=${envConfig.mainServiceUrl}`;
      const body = {
        LoginSecuredHash: getLoginSecured(email, password),
        UserLogin2FACode: code,
        RememberMeYN: remember ? "Y" : "N",
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "BTM-WS2019",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": getCookie("apiKey") + "-0",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          if (response.status === 401) {
            await generateNewApiKeyAndValidate2FA();
          } else {
            const error = await response.json();
            throw new Error(error.message);
          }
        } else {
          const data = await response.json();
          Cookies.set("apiKey", data.ApiKey, { path: "/" });
          Cookies.set(
            "apiKeyExpirationUTCDate",
            data.ApiKeyExpiryUTC.slice(0, -1),
            {
              path: "/",
            }
          );
          Cookies.set("sessionId", data.SessionID, { path: "/" });
          Cookies.set("firstName", data.FirstName, { path: "/" });
          Cookies.set("lastName", data.LastName, { path: "/" });
          setPassword("");
          setCode("");
          handleClose();
          if (window.location.href.includes("user-dashboard")) {
            navigate(0);
          }
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  };

  const generateNewApiKeyAndValidate2FA = async () => {
    const generateTokenUrl = `https://${envConfig.apiLogin}/api/v1/en-au/system/generate-new-api-token-details?BaseHostURL=${envConfig.loginUrl}`;
    const loginSecured = getLoginSecured(email, password);
    const generateTokenData = {
      LoginSecuredHash: loginSecured,
    };

    try {
      const generateTokenResponse = await fetch(generateTokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "127.0.0.2",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "browser-agent": window.navigator.userAgent,
          "api-key": "OlivsAuth",
        },
        body: JSON.stringify(generateTokenData),
      });

      if (!generateTokenResponse.ok) {
        const error = await generateTokenResponse.json();
        throw new Error(error.message);
      }

      const generateTokenJson = await generateTokenResponse.json();
      if (generateTokenJson?.ApiKey) {
        const verificationUrl = `https://${envConfig.apiLogin}/api/v1/en-au/system/validate-2fa-code?BaseHostURL=${envConfig.loginUrl}`;
        const verificationData = {
          UserLogin2FACode: code,
          RememberMeYN: remember ? "Y" : "N",
        };

        const verificationResponse = await fetch(verificationUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "BTM-WS2019",
            "session-id": generateTokenJson.SessionID,
            "browser-agent": window.navigator.userAgent,
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": generateTokenJson.ApiKey + "-0",
          },
          body: JSON.stringify(verificationData),
        });

        if (!verificationResponse.ok) {
          const error = await verificationResponse.json();
          throw new Error(error.message);
        }

        const verificationJson = await verificationResponse.json();
        if (verificationJson.SuccessYN === "Y") {
          Cookies.set("apiKey", generateTokenJson.ApiKey, {
            path: "/",
          });
          Cookies.set(
            "apiKeyExpirationUTCDate",
            generateTokenJson.ApiKeyExpiryUTC.slice(0, -1),
            {
              path: "/",
            }
          );
          Cookies.set("sessionId", generateTokenJson.SessionID, {
            path: "/",
          });
          Cookies.set("firstName", generateTokenJson.FirstName, {
            path: "/",
          });
          Cookies.set("lastName", generateTokenJson.LastName, {
            path: "/",
          });
          setPassword("");
          setCode("");
          handleClose();
          if (window.location.href.includes("user-dashboard")) {
            navigate(0);
          }
        } else {
          enqueueSnackbar(verificationJson.ErrorMessage, {
            variant: "error",
          });
        }
      } else if (generateTokenJson.SuccessYN === "N") {
        enqueueSnackbar(generateTokenJson.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const sendCodeViaSMS = async () => {
    try {
      setSmsSent(true);
      const smsUrl = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=${envConfig.mainServiceUrl}`;

      const headers = {
        Accept: "application/json",
        "olivs-api-real-ip": "115.70.37.103",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "session-id": getCookie("sessionId"),
        "browser-agent": window.navigator.userAgent,
        "api-key": getCookie("apiKey") + "-0",
      };

      setTimeout(async () => {
        const smsResponse = await fetch(smsUrl, {
          method: "POST",
          headers: headers,
        });

        const smsJson = await smsResponse.json();
        if (smsJson?.SmsSentYN === "Y") {
          enqueueSnackbar("SMS sent successfully", { variant: "success" });
        } else {
          setSmsSent(false);
          enqueueSnackbar(smsJson?.ErrorMessage || smsJson, {
            variant: "error",
          });
        }
      }, 10000);
    } catch (error) {
      setSmsSent(false);
      enqueueSnackbar(
        "Error occurred while sending the code. Please try again.",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    const email = getCookie("loginEmail");
    setEmail(email);
    setLoginEmail(email);
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "r") {
        setRemember((prevRemember) => !prevRemember);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backdropFilter: "blur(3px)",
          },
        }}
        open={open}
        onClose={(e, reason) => {
          if (reason && reason === "backdropClick") return;
          deleteAllCookies();
          console.log(reason);
          window.location = `https://${envConfig.loginUrl}/`;
        }}
      >
        <DialogTitle>Session expired</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={email}
            disabled={email === loginEmail && loginEmail !== "" && loginEmail}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mt: 2,
              maxWidth: "480px",
              display: "block",
              label: { fontSize: 16, marginTop: 0 },
              "p.Mui-error": {
                position: "absolute",
              },
            }}
            size="small"
          />
          <TextField
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="small"
            sx={{
              mt: 2,
              maxWidth: "480px",
              display: "block",
              label: { fontSize: 16, marginTop: 0 },
              "p.Mui-error": {
                position: "absolute",
              },
            }}
          />
          <TextField
            label="2FA Code"
            type="text"
            onKeyDown={(e) => {
              console.log(e.key);
              if (e.key === "Enter" && code?.length === 6 && password) {
                handleLogin();
              }
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            sx={{
              mt: 2,
              maxWidth: "480px",
              display: "block",
              label: { fontSize: 16, marginTop: 0 },
              "p.Mui-error": {
                position: "absolute",
              },
            }}
          />
          <Link
            className={"link " + smsSent ? "disabled" : ""}
            onClick={() => {
              if (!smsSent) sendCodeViaSMS();
            }}
            aria-disabled={smsSent}
            style={{
              color: smsSent ? "#888888" : isDarkMode ? "#AAD269" : "#849F23",
              marginTop: 0,
              cursor: smsSent ? "default" : "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            Send code via SMS
          </Link>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                onChange={(e) => setRemember(e.target.checked)}
              />
            }
            label={
              <span>
                <u>R</u>emember Me
              </span>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => {
              deleteAllCookies();
              window.location = `https://${envConfig.loginUrl}/`;
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={code?.length !== 6 || !password}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            variant="primary"
            onClick={handleLogin}
          >
            Log In
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginPopup;
