import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { IconButton } from "@mui/material";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { GridCloseIcon } from "@mui/x-data-grid-pro";

const action = (key) => (
  <IconButton
    size="small"
    aria-label="close"
    color="inherit"
    onClick={() => closeSnackbar(key)}
  >
    <GridCloseIcon fontSize="small" />
  </IconButton>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider maxSnack={8} action={action} autoHideDuration={6000}>
    <App />
  </SnackbarProvider>
);
