import React from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { ReactComponent as Members } from "../../assets/users.svg";
import { ReactComponent as BusinessFiles } from "../../assets/files.svg";
import { ReactComponent as LinkATO } from "../../assets/link.svg";
import { ReactComponent as EditDetails } from "../../assets/pencil.svg";

const getTabIcon = (group, isSelected, isDarkMode) => {
  const iconStyle = {
    marginRight: "8px",
    width: "24px",
    height: "24px",
    stroke: isSelected ? "#849F23" : isDarkMode ? "#E8E8E8" : "#252525",
  };

  switch (group) {
    case "Members":
      return <Members style={iconStyle} />;
    case "Business Files":
      return <BusinessFiles style={iconStyle} />;
    case "Link Subscriber to ATO":
      return <LinkATO style={iconStyle} />;
    case "Details":
      return <EditDetails style={iconStyle} />;
    default:
      return <span style={iconStyle}>•</span>;
  }
};

const borderExpand = {
  "@keyframes expand": {
    "0%": { width: "0%", left: "50%" },
    "100%": { width: "100%", left: "0%" },
  },
};

const CustomTabs = ({ selectedTab, handleTabChange }) => {
  const availableGroups = [
    "Members",
    "Business Files",
    "Link Subscriber to ATO",
    "Details",
  ];
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="report tabs"
      variant="scrollable"
      scrollButtons={false}
      TabIndicatorProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      {availableGroups.map((group, index) => {
        const isSelected = selectedTab === index;

        return (
          <Tab
            disableRipple
            key={group}
            value={index}
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 1,
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    width: "0%",
                    height: "2px",
                    backgroundColor: isSelected
                      ? theme.palette.primary.main
                      : "transparent",
                    animation: isSelected ? "expand 0.4s forwards" : "none",
                  },
                  ...borderExpand,
                }}
              >
                {getTabIcon(group, isSelected, isDarkMode)}
                <Typography
                  sx={{
                    color: isSelected
                      ? theme.palette.primary.main
                      : theme.palette.blackWhite.main,
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                      md: "24px",
                    },
                    fontWeight: "400",
                    leadingTrim: "both",
                    textEdge: "cap",
                  }}
                >
                  {group}
                </Typography>
              </Box>
            }
            sx={{
              minWidth: "auto",
              padding: {
                xs: "8px 16px",
                sm: "10px 20px",
                md: "12px 24px",
              },
              textTransform: "none",
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;
