import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LOGIN_API_OLIVS_APP_URL } from "../../../utils/common";
import envConfig from "../../../config";
import Cookies from "js-cookie";

const VerifyEmailHash = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://${envConfig.apiDev1}/api/v3/en-au/users/find-by-email-verification-hash?sendsmsYN=Y&hash=${email}&BaseHostURL=${envConfig.loginUrl}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "olivs-api-real-ip": "115.70.37.103",
              "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "api-key": "OlivsAuth",
            },
          }
        );
        const data = await response.json();

        if (data?.SuccessYN === "Y") {
          try {
            // If API key is not valid for at least 15 minutes, generate a new one
            const generateTokenUrl =
              LOGIN_API_OLIVS_APP_URL +
              `en-au/system/generate-new-api-token-details?BaseHostURL=${envConfig.loginUrl}`;

            const generateTokenData = JSON.stringify({
              LoginSecuredHash: data?.LoginSecuredHash,
            });

            const headers = {
              "Content-Type": "application/json",
              Accept: "application/json",
              "olivs-api-real-ip": "115.70.37.103",
              "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "api-key": "OlivsAuth",
            };

            const generateTokenResponse = await fetch(generateTokenUrl, {
              method: "POST",
              headers: {
                ...headers,
                "browser-agent": window.navigator.userAgent,
              },
              body: generateTokenData,
            });

            const generateTokenJson = await generateTokenResponse.json();

            const approveEmailUrl = `https://${envConfig.apiDev1}/api/v3/en-au/users/approve-login-email?BaseHostURL=${envConfig.loginUrl}`;

            const approveEmailData = JSON.stringify({
              LoginSecuredHash: data?.LoginSecuredHash,
            });

            console.log(generateTokenJson);

            const approveEmailResponse = await fetch(approveEmailUrl, {
              method: "POST",
              headers: headers,
              body: approveEmailData,
            });
            const approveEmailJson = await approveEmailResponse.json();

            Cookies.set("apiKey", generateTokenJson.ApiKey, { path: "/" });
            Cookies.set("sessionId", generateTokenJson.SessionID, {
              path: "/",
            });
            Cookies.set("databaseId", "0", { path: "/" });
            Cookies.set(
              "apiKeyExpirationUTCDate",
              generateTokenJson.ApiKeyExpiryUTC.slice(0, -1),
              {
                path: "/",
              }
            );

            navigate("/verify-phone", {
              state: {
                loginSecuredHash: data?.LoginSecuredHash,
                phone: data?.Phone,
                apiKey: generateTokenJson?.ApiKey + "-0",
                sessionId: generateTokenJson?.SessionID,
              },
            });
          } catch (error) {
            console.error("Error:", error);
            navigate("/");
          }
        } else {
          console.error(data?.ErrorMessage);
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        navigate("/");
      }
    };

    if (email) {
      fetchData();
    } else {
      navigate("/");
    }
  }, [navigate, location.search]);

  return null;
};

export default VerifyEmailHash;
