import React from "react";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useMediaQuery } from "@mui/material";

const HelpTooltip = ({ tooltipContent }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Tooltip
      title={tooltipContent}
      arrow
      placement="right"
      slotProps={{
        arrow: {
          sx: {
            color: "#495670",
          },
        },
        tooltip: {
          sx: {
            bgcolor: "#495670",
            maxWidth: isMobile ? "200px" : "357px",
            minWidth: isMobile ? "200px" : "350px",
          },
        },
      }}
    >
      <HelpOutlineIcon
        sx={{
          fontSize: "20px",
          cursor: "pointer",
          color: "#495670",
        }}
      />
    </Tooltip>
  );
};

export default HelpTooltip;
